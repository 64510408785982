import React from 'react';

import Category from 'components/help/category';

import { integrations } from 'data/helpArticles';

export default () => (
  <Category
    title="Integrations"
    integrations
    articles={integrations}
    url="/help/integrations/"
    description="Integrate LiveSession with top SaaS tools, including Intercom, Segment, LiveChat, Crisp, HelpScout and more. Add more context to your session recordings."
  />
);
